<template>
  <form @submit.prevent="save" class="w-m-content" v-if="!loading && !errors && !success">
    <div class="w-m-header">
      <p class="font-14 font-bold no-margin" style="color: #293e52">Cadastre e defina permissões para novos
        colaboradores</p>
      <div class="m-t-xs font-13 text-grey-7" style="color: #748396; padding-right: 20px; line-height: 1">Esta seção
        autoriza uma pessoa a acessar o software, com suas respectivas permissões.
      </div>
    </div>
    <div class="w-m-body e-input-modern">
      <div style="min-height: 400px">
        <e-row class="m-b-sm font-bold">
          Nome completo:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input autofocus simple-border required placeholder="Digite o nome da pessoa" v-model="model.name" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          Nome do usuário para acesso:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input simple-border required placeholder="Digite o login, ex: tiagofelipe" v-model="model.username" />
            <!-- TODO: Fazer endpoint para verificar username e gerar auto -->
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs font-bold">
          E-mail:
        </e-row>
        <e-row class="m-b-sm">
          <e-col>
            <erp-input simple-border required type="email" placeholder="Digite o email do colaborador"
                       v-model="model.email" />
          </e-col>
        </e-row>

        <e-row class="m-b-sm m-t-n-xs">
          <label class="flex items-center">
            <u-checkbox v-model="model.generatePassword" />
            <span class="font-13 m-l-xs">Solicitar ao colaborador por e-mail a criação de uma senha</span></label>
        </e-row>

        <e-row class="m-b-sm m-t">
          <e-col>
            <strong>Permissões:</strong>
            <e-row class="m-t-sm">
              <e-col v-if="!isComitente">
                <label class="flex items-center m-b-xs">
                  <u-radio v-model="model.admin" :val="true" class="m-r-xs" />
                  Administrador</label>
                <label class="flex items-center">
                  <u-radio v-model="model.admin" :val="false" class="m-r-xs" />
                  Limitado</label>
                <div v-if="!model.admin" class="font-11 m-t-xs text-blue-grey-4">A definição das permissões poderá ser
                  realizada após o cadastro
                </div>
              </e-col>
              <e-col v-else>
                <label class="flex items-center">
                  <u-radio v-model="model.admin" :val="false" class="m-r-xs" />
                  Limitado (Somente acesso comitente)</label>
              </e-col>
            </e-row>
          </e-col>
        </e-row>
      </div>
    </div>
    <div class="w-m-footer text-right">
      <u-btn type="submit" label="Cadastrar" :disable="!ready" no-caps :color="ready ? 'primary' : 'blue-grey-2'"
             class="b-radius-3px" />
    </div>
  </form>
  <window-success v-else-if="success">
    <div class="text-center">
      <u-icon name="check-circle" color="positive" type="fa" icon-style="solid" class="success-circle-140" />
    </div>
    <h5 class="m-t">Membro cadastrado com sucesso!</h5>
    <div class="m-t text-blue-grey-7">
      Ele receberá um e-mail com as informações de acesso.<br><br>
      Foi gerada uma senha automaticamente para este usuário:<br><br>
      <div class="text-2x font-bold text-center">{{model.password}}</div>
    </div>
    <div class="text-center m-t">
      <timer-close-window ref="timer" @end="$uloc.window.close($root.wid)" />
    </div>
  </window-success>
  <window-loading :errors="errors" :status="status" v-else />
</template>

<script>
import ERow from '../../layout/components/Row'
import {ErpInput} from 'uloc-vue-plugin-erp'
import ECol from '../../layout/components/Col'
import {UCheckbox, URadio} from 'uloc-vue'
import WindowSuccess from '../../layout/window/Success'
import TimerCloseWindow from '../../layout/window/TimerClose'
import WindowLoading from '../../layout/window/Loading'
import {create} from '@/domain/usuario/services'

let mock = {
  id: null,
  name: null,
  username: null,
  email: null,
  roles: ['ROLE_API', 'ROLE_ERP'],
  acl: [],
  generatePassword: true,
  admin: true,
  comitente: null
}

export default {
  name: 'UsuarioWindowModern',
  components: {WindowLoading, TimerCloseWindow, WindowSuccess, ECol, ERow, ErpInput, UCheckbox, URadio},
  props: ['props', 'id', 'tipo'],
  data () {
    return {
      model: JSON.parse(JSON.stringify(mock)),
      loading: !!this.id,
      errors: null,
      success: false,
      status: null
    }
  },
  mounted () {
    console.log('Teste', this.id, this.tipo)
    if (this.isComitente) {
      this.loading = false
      this.model.admin = false
      this.model.comitente = this.id
    }
  },
  computed: {
    isComitente () {
      return this.tipo && this.tipo === 'comitente'
    },
    mock () {
      return mock
    },
    ready () {
      let model = this.model
      if (String(model.name).length > 2 && model.email && model.username) {
        return true
      }
      return false
    }
  },
  methods: {
    save () {
      console.log('Save')
      this.loading = true

      let data = this.model

      if (data.admin) {
        data.roles.push('ROLE_ADMIN')
      }
      create(data)
        .then(({data}) => {
          this.success = true
          Object.assign(this.model, data)
          this.$nextTick(() => {
            this.$uloc.window.emit(this.$root.wid, 'created', this.props)
            // this.$refs.timer.start()
          })
        })
        .catch(error => {
          this.success = false
          this.errorMessage = error.data.message
          // this.errors = this.systemError(error)
          this.$nextTick(() => {
            this.loading = false
            this.alertApiError(error)
            // this.error = true
          })
        })
    }
  }
}
</script>
